import axios from 'axios';

const DEFAULT_METHOD = 'post';

const methods = {
  // articles
  'articles.categories': { url: '/articles/categories' },
  'articles.get': { url: '/articles/get' },
  'articles.main': { url: '/articles/main' },
  'articles.list': { url: '/articles/list' },

  // banner (alias)
  '/endpoint/banner/slider': { url: '/endpoint/banner/slider' },
  'banner.thx': { url: '/banner1/thx' },
  // cart
  'cart.item': { url: '/cart/item' },
  'cart.items': { url: '/cart/items' },
  'cart.get_additionally': { url: '/cart/get_additionally' },
  'cart.get_recommendations': { url: '/cart/get_recommendations' },
  'cart.gifts': { url: '/cart/gifts' },
  'cart.remove.user.gifts': { url: '/cart/remove_user_gift' },
  'cart.remove': { url: '/cart/remove' },
  'cart.product_promocode': { url: '/cart/product_promocode' },
  'cart.promocode': { url: '/cart/promocode' },
  'cart.order_date': { url: '/cart/order_date' },
  'cart.bonus_phone': { url: '/cart/bonus_phone' },
  'cart.get': { url: '/cart/get' },
  'cart.user_gift': { url: '/cart/user_gift' },
  'cart.refresh': { url: '/cart/refresh' },
  'cart.reset': { url: '/cart/reset', method: 'get' },

  'cart.get_server_date': { url: '/cart/get_server_date' },

  // contest
  'contest.main': { url: '/contest/main' },
  'contest.list': { url: '/contest/list' },

  // favorites
  'favorites.list': { url: '/favorites/list' },
  'favorites.toggle': { url: '/favorites/toggle' },

  // main
  'main.products': { url: '/main/products' },

  // news
  'news.list': { url: '/news/list' },
  'news.main': { url: '/news/main' },
  'news.categories': { url: '/news/categories' },
  'news.get': { url: '/news/get' },

  // geo
  // 'geo.get_address': { url: '/geo/get_address' },

  // orders
  // 'order.delivery_check': { url: '/order/delivery_check' },
  'order.get_recommendations': { url: '/order/get_recommendations' },
  'order.status': { url: '/order/status' },
  'order.check_promo_code': { url: '/order/check_promo_code' },

  // products
  'product.get': { url: '/product/get' },
  'product.city_products': { url: '/product/city_products' },
  'product.get_additionally': { url: '/product/get_additionally' },
  'product.group_wok': { url: '/product/group_wok' },
  'product.list': { url: '/product/list' },
  'product.products_month': { url: '/product/products_month' },
  'product.recommendations': { url: '/product/get_recommendations' },
  'product.viewed': { url: '/product/viewed' },

  // reviews
  'review.list': { url: '/review/list' },

  // vacancies
  vacancies: { url: '/vacancies' },
  'vacancies.get': { url: '/vacancies/get' },

  // search
  search: { url: '/search' },
  'search.to_stat': { url: '/search/to_stat' },
  'search.news.get': { url: '/search/news/get' },
  'search.stock.get': { url: '/search/stock/get' },
  'search.article.get': { url: '/search/article/get' },

  // seo
  'seo.page': { url: '/seo/page' },
  'seo.redirect': { url: '/seo/redirect' },

  // settings
  'settings.mainpage': { url: '/settings/mainpage' },
  '/endpoint/setting/promo_links': { url: '/endpoint/setting/promo_links' },
  // stock
  'stock.catalog': { url: '/stock/catalog' },
  'stock.get_grid': { url: '/stock/grid' },
  'stock.get_types': { url: '/stock/types' },
  'stock.get_categories': { url: '/stock/categories' },
  'stock.main': { url: '/stock/main' },
  'stock.get': { url: '/stock/get' },
  'stock.index': { url: '/stock/index' },

  // subscribe
  'subscribe.email': { url: '/subscribe/email' },
  'subscribe.confirm': { url: '/subscribe/confirm' },
  'subscribe.unsubscribe': { url: '/subscribe/unsubscribe' },

  // stores
  'store.get_list_city': { url: '/store/get_list_city' },
  'store.get_stopped': { url: '/store/get_stopped' },
  'store.get_stopped_products': { url: '/store/get_stopped_products' },

  // user
  'user.addresses': { url: '/user/addresses' },
  'user.address': { url: '/user/address' },
  'user.password.change': { url: '/user/password/change' },
  'user.remove_address': { url: '/user/remove_address' },
  'user.register_or_auth': { url: '/user/register_or_auth' },
  'user.update': { url: '/user/profile/update' },
  'user.get_bonuses': { url: '/user/get_bonuses' },
  'user.isExist': { url: '/user/isExist', method: 'get' },
  '/endpoint/phone/validate': {
    url: '/endpoint/phone/validate',
    method: 'get',
    headers: {
      'Api-Version': 3,
    },
  },
};

export default (path, data, params) => {
  if (!methods[path]) {
    throw new { message: `path ${path} not defined` }();
  }

  return axios({
    url: methods[path].url,
    method: methods[path].method || DEFAULT_METHOD,
    data,
    params,
    headers: methods[path].headers || {},
  })
    .then(({ data }) => {
      return data;
    })
    .catch((e) => {
      throw e.response;
    });
};
